import {dataTestId} from '@hconnect/uikit'
import {Box, Stack} from '@mui/material'
import React, {useMemo} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {TextWithCaption} from '../../../common/components/TextWithCaption/TextWithCaption'
import {useMapMeasurementTypeMutation} from '../../hooks/mutations/useMapMeasurementTypeMutation'
import {useUnmapMeasurementTypeMutation} from '../../hooks/mutations/useUnmapMeasurementTypeMutation'
import {ExtendedGlobalMeasurementType} from '../../types/GlobalMeasurementType'
import {ExtendedLimsMeasurementType, LimsMeasurementType} from '../../types/LimsMeasurementType'
import {getLimsMeasurementTypeLabel, isArrayContainingSameElements} from '../../utils'
import {AutocompleteOption, MultiAutoComplete} from '../MultiAutoComplete/MultiAutoComplete'

export interface LimsMeasurementTypesFormProps {
  globalMeasurementType: ExtendedGlobalMeasurementType
  unmappedLimsMeasurementTypes: LimsMeasurementType[]
  canChangeMappings: boolean
  plantCode: string
}

export function LimsMeasurementTypesForm({
  globalMeasurementType,
  unmappedLimsMeasurementTypes,
  canChangeMappings,
  plantCode
}: LimsMeasurementTypesFormProps): JSX.Element {
  const {t} = useTranslation()

  const {mutate: mapMeasurementType, isLoading: isMeasurementTypeBeingMapped} =
    useMapMeasurementTypeMutation()
  const {mutate: unmapMeasurementType, isLoading: isMeasurementTypeBeingUnmapped} =
    useUnmapMeasurementTypeMutation()

  const {
    id: globalTypeId,
    name: globalTypeName,
    description: globalTypeDescription,
    unit: globalTypeUnit,
    limsMeasurementTypes
  } = globalMeasurementType

  const defaultValues = useMemo(
    () => limsMeasurementTypes.map((limsType) => limsType.code),
    [limsMeasurementTypes]
  )

  const {handleSubmit, control, reset} = useForm<{limsCodes: string[]}>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {limsCodes: defaultValues}
  })

  const handleMeasurementTypeMapping = handleSubmit(({limsCodes: newLimsCodes}) => {
    if (!canChangeMappings || isArrayContainingSameElements(newLimsCodes, defaultValues)) return

    if (newLimsCodes.length > defaultValues.length) {
      // User is mapping a new lims measurement type to a global measurement type
      const limsCodeBeingAdded = newLimsCodes.filter((code) => !defaultValues.includes(code))[0]
      const limsIdBeingAdded = (
        unmappedLimsMeasurementTypes.find(
          (limsType) => limsType.code === limsCodeBeingAdded
        ) as LimsMeasurementType
      ).id
      mapMeasurementType(
        {
          plantCode,
          globalMeasurementTypeId: globalTypeId,
          limsMeasurementTypeId: limsIdBeingAdded
        },
        {
          onSuccess: () => reset({limsCodes: newLimsCodes}),
          onError: () => reset()
        }
      )
    } else {
      // User is unmapping lims measurement type from a global measurement type
      const limsCodesBeingDeleted = defaultValues.filter((code) => !newLimsCodes.includes(code))
      limsCodesBeingDeleted.forEach((limsCodeBeingDeleted) => {
        const mappingIdBeingDeleted = (
          limsMeasurementTypes.find(
            (limsType) => limsType.code === limsCodeBeingDeleted
          ) as ExtendedLimsMeasurementType
        ).mappingId
        unmapMeasurementType(
          {
            plantCode,
            measurementTypeMappingId: mappingIdBeingDeleted
          },
          {
            onSuccess: () => reset({limsCodes: newLimsCodes}),
            onError: () => reset()
          }
        )
      })
    }
  })

  const autoCompleteOptions = useMemo<AutocompleteOption[]>(
    () =>
      [...limsMeasurementTypes, ...unmappedLimsMeasurementTypes].map<AutocompleteOption>(
        (limsMeasurementType) => ({
          value: limsMeasurementType.code,
          label: getLimsMeasurementTypeLabel(limsMeasurementType)
        })
      ),
    [limsMeasurementTypes, unmappedLimsMeasurementTypes]
  )

  const isMeasurementTypeFormDisabled =
    !canChangeMappings || isMeasurementTypeBeingMapped || isMeasurementTypeBeingUnmapped

  return (
    <Stack
      key={globalTypeId}
      direction="row"
      alignItems="center"
      spacing={2}
      {...dataTestId(`measurement_type_${globalTypeName}`)}
    >
      <Box sx={{width: (theme) => theme.spacing(40)}}>
        <TextWithCaption
          text={`${globalTypeName}${globalTypeUnit ? ` (${globalTypeUnit})` : ''}`}
          caption={globalTypeDescription}
          {...dataTestId(`measurement_type_${globalTypeName}_info`)}
        />
      </Box>
      <Controller
        control={control}
        name="limsCodes"
        render={({field: {value, onChange}}) => (
          <MultiAutoComplete
            values={value}
            options={autoCompleteOptions}
            onChange={(newCodes) => {
              onChange(newCodes)
              void handleMeasurementTypeMapping()
            }}
            label={t('measurementsSettings.limsMeasurementType')}
            isDisabled={isMeasurementTypeFormDisabled}
            {...dataTestId(`measurement_type_${globalTypeName}_autocomplete`)}
          />
        )}
      />
    </Stack>
  )
}
