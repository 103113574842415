import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {UseQueryResult} from 'react-query'

import {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'
import {AutocompleteOption} from '../../types'

import {useSettingsQuery} from '@settings/modules/common/providers'
import {useUrlParam} from '@settings/modules/routing'

export const useAssetOptionsQuery = (): UseQueryResult<AutocompleteOption[]> => {
  const plantCode = useUrlParam('plantCode')
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  return useSettingsQuery('assets', [plantCode], {
    select: (assets) =>
      assets
        .map<AutocompleteOption>(({id, name}) => ({
          id,
          label: name
        }))
        .sort((current, next) => (current.label > next.label ? 1 : -1)),

    onError: (error) => {
      const axiosError = error as AxiosError<ErrorResponse>
      const errorDetails =
        axiosError?.response?.data.detail ?? axiosError.message ?? axiosError.response?.status

      notifyIfErrorMessage(
        t('samplingPointsSettings.queries.fetchAssetsError', {
          details: errorDetails
        }),
        raiseError,
        notify
      )
    }
  })
}
