import {dataTestId} from '@hconnect/uikit'
import {Check} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../permissions'
import {useHandleSamplingPointsMapping} from '../hooks/useHandleSamplingPointsMapping'
import {LimsSamplingPoint, AutocompleteOption, SamplingPointTypesAutocompleteOption} from '../types'

import {SamplingPointsSettingsTable} from './SamplingPointsSettingsTable/SamplingPointsSettingsTable'

import {useUrlParam} from '@settings/modules/routing'

interface SamplingPointsFormsProps {
  limsSamplingPoints: LimsSamplingPoint[]
  samplingPointTypeOptions: SamplingPointTypesAutocompleteOption[]
  assetOptions: AutocompleteOption[]
  storageOptions: AutocompleteOption[]
}

export const SamplingPointsSettingsContent: React.FC<SamplingPointsFormsProps> = ({
  limsSamplingPoints,
  samplingPointTypeOptions,
  assetOptions,
  storageOptions
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const canChangeSamplingPoints = usePermission('CHANGE_MATERIALS')

  const {isMappingSamplingPoints, handleSamplingPointsMapping} =
    useHandleSamplingPointsMapping(limsSamplingPoints)

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" padding={1}>
        <Box sx={{fontWeight: 600, fontSize: 20}} {...dataTestId('sampling_points_settings_title')}>
          {t('samplingPointsSettings.samplingPoints')}
        </Box>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Check />}
          disabled={!canChangeSamplingPoints || isMappingSamplingPoints}
          onClick={handleSamplingPointsMapping}
          {...dataTestId('sampling_points_settings_save_button')}
        >
          {t('common.save')}
        </Button>
      </Stack>
      <Box maxWidth={865}>
        <SamplingPointsSettingsTable
          limsSamplingPoints={limsSamplingPoints}
          samplingPointTypeOptions={samplingPointTypeOptions}
          assetOptions={assetOptions}
          storageOptions={storageOptions}
          plantCode={plantCode}
        />
      </Box>
    </Stack>
  )
}
