import {SamplingPointType, SamplingPointTypesAutocompleteOption} from '../types'

export const orderSamplingPointTypeOptions = (
  samplingPointTypeOptions: SamplingPointTypesAutocompleteOption[]
): SamplingPointTypesAutocompleteOption[] => {
  // Hardcoding the order that matches the industry process logic for sampling point types
  const samplingPointTypesOrder = [
    0, 1, 2, 3, 4, 22, 5, 6, 7, 8, 9, 10, 11, 12, 13, 21, 23, 24, 14, 15, 16, 19, 17, 18, 20
  ]

  // Sorting sampling point types with known order
  const presentTypes = samplingPointTypeOptions
    .filter((option) => samplingPointTypesOrder.includes(option.id))
    .sort((current, next) => {
      const currentTypePosition = samplingPointTypesOrder.indexOf(current.id)
      const nextTypePosition = samplingPointTypesOrder.indexOf(next.id)
      return currentTypePosition > nextTypePosition ? 1 : -1
    })

  // Handling the case when the type provided from BE is not present in the samplingPointTypesOrder array
  // and putting such sampling point types at the end of the list
  const missingTypes = samplingPointTypeOptions.filter(
    (option) => !samplingPointTypesOrder.includes(option.id)
  )

  return [...presentTypes, ...missingTypes]
}

export const getSamplingPointTypeOptions = (
  samplingPointTypes: SamplingPointType[]
): SamplingPointTypesAutocompleteOption[] => {
  const samplingPointTypeOptions = samplingPointTypes.map<SamplingPointTypesAutocompleteOption>(
    (samplingPointType) => ({
      id: samplingPointType.id,
      label: samplingPointType.name,
      type: samplingPointType.equipmentType
    })
  )
  return orderSamplingPointTypeOptions(samplingPointTypeOptions)
}
