import {dataTestId} from '@hconnect/uikit'
import {Loader} from '@hconnect/uikit/src/lib2'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {Box, Button, Stack, TextField} from '@mui/material'
import {debounce} from 'lodash'
import {useEffect, useMemo, useRef, useState} from 'react'
import {Tree, TreeApi} from 'react-arborist'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import useResizeObserver from 'use-resize-observer'

import {PlantStructureFilterOptions} from '../../common/enums'
import {useGetPlantStructure} from '../../common/hooks/queries/usePlantStructureQuery'
import {TreeNode} from '../../common/types'
import {useSelectedItem} from '../providers'

import {AddSubdomain} from './AddSubdomain'
import {useHandleNodeSelected, useHandleTreeFiltering} from './hooks'
import {PlantStructureNode} from './PlantStructureNode'

const getPlantStructureProductFilter = (selectedFilterOption: PlantStructureFilterOptions) => {
  switch (selectedFilterOption) {
    case PlantStructureFilterOptions.Janus:
      return PlantStructureFilterOptions.Janus
    default:
      return undefined
  }
}

interface PlantStructureTreeProps {
  selectedFilterOption: PlantStructureFilterOptions
}

export const PlantStructureTree: React.FC<PlantStructureTreeProps> = ({selectedFilterOption}) => {
  const {t} = useTranslation()
  const {setSelectedItem, setUpmPlantId} = useSelectedItem()

  const [searchParams] = useSearchParams()
  const nodeIdSearchParam = searchParams.get('entityId') ?? undefined

  // Text filter and equipment visibility
  const [filterText, setFilterText] = useState<string>('')
  const debouncedSetFilterText = debounce((text: string) => setFilterText(text), 300)

  const [includeEquipment, setIncludeEquipment] = useState<boolean>(true)

  // Queries
  const {data: plantStructure} = useGetPlantStructure({
    includeEquipment:
      includeEquipment && selectedFilterOption !== PlantStructureFilterOptions.Janus,
    product: getPlantStructureProductFilter(selectedFilterOption)
  })

  // Plant structure tree
  const treeRef = useRef<TreeApi<TreeNode>>()
  const tree = treeRef.current

  const {ref: treeContainerRef, width, height} = useResizeObserver()
  const arboristTree = useMemo(() => (plantStructure ? [plantStructure] : []), [plantStructure])

  const handleNodeSelected = useHandleNodeSelected()
  const {searchTerm, handleTreeFiltering} = useHandleTreeFiltering(selectedFilterOption, filterText)

  const plantId: string = plantStructure?.upmId ?? ''

  useEffect(() => {
    if (plantStructure) {
      setUpmPlantId(plantStructure?.upmId)
    }
  }, [plantStructure, setUpmPlantId])

  // If selected node is present in the tree --> set selected item accordingly,
  // otherwise select the first node
  useEffect(() => {
    if (!tree) return
    const selectedNode = tree.selectedNodes[0]

    if (selectedNode) {
      setSelectedItem(selectedNode.data)
    } else {
      handleNodeSelected(tree.firstNode)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree, tree?.selectedNodes])

  if (!plantStructure) {
    return <Loader />
  }

  return (
    <>
      <Box>
        <Stack
          direction="column"
          justifyContent="space-between"
          {...dataTestId('janus-plant-structure-menu')}
          alignItems={'left'}
          gap={2}
        >
          <Box px={2}>
            <TextField
              fullWidth
              label={t('janusConfig.plantSetup.freeTextFilter')}
              variant="filled"
              onChange={(e) => debouncedSetFilterText(e.target.value)}
              {...dataTestId('plant-structure-text-filter')}
            />
          </Box>
          <Box display="flex" gap={1} px={2} flexWrap={'wrap'} justifyContent="space-between">
            <Box>
              {selectedFilterOption !== PlantStructureFilterOptions.Janus && (
                <Button
                  onClick={() => setIncludeEquipment((prev) => !prev)}
                  size="small"
                  {...dataTestId('show-hide-equipments-button')}
                  variant="text"
                  startIcon={includeEquipment ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  sx={{p: 0.5}}
                >
                  {includeEquipment
                    ? t('janusConfig.action.hideEquipment')
                    : t('janusConfig.action.showEquipment')}
                </Button>
              )}
            </Box>
            <Box display="flex" gap={1} flexWrap={'wrap'}>
              <Button
                onClick={() => treeRef.current?.openAll()}
                size="small"
                variant="text"
                startIcon={<UnfoldMoreIcon />}
                sx={{p: 0.5}}
              >
                {t('janusConfig.action.expand')}
              </Button>
              <Button
                onClick={() => {
                  treeRef.current?.closeAll()
                  treeRef.current?.open(plantId)
                }}
                size="small"
                variant="text"
                startIcon={<UnfoldLessIcon />}
                sx={{p: 0.5}}
              >
                {t('janusConfig.action.collapse')}
              </Button>
            </Box>
          </Box>
          <Box ref={treeContainerRef} height={'55vh'}>
            {arboristTree && (
              <Tree
                ref={treeRef}
                data={arboristTree}
                openByDefault={false}
                width={width}
                rowHeight={56}
                height={height}
                indent={20}
                searchTerm={searchTerm}
                searchMatch={handleTreeFiltering}
                idAccessor={(node) => node.upmId}
                selection={nodeIdSearchParam}
                onSelect={(nodes) => handleNodeSelected(nodes[0])}
                initialOpenState={{[plantId]: true}}
              >
                {PlantStructureNode}
              </Tree>
            )}
          </Box>
        </Stack>
      </Box>
      <AddSubdomain />
    </>
  )
}
