import {useSettingsQuery} from '../../../common/providers/ApiInterface'

import {UpmEntityType} from '@settings/modules/common/types'

export const useEntityConfig = (upmPlantId: string, entityId?: string, type?: UpmEntityType) => {
  return useSettingsQuery('upmEntityConfig', [upmPlantId, entityId, type], {
    enabled: !!entityId,
    cacheTime: 0,
    retry: false,
    staleTime: 0,
    onError: (error) => error
  })
}

export const QueryKey = 'upmEntityConfig'
