import {dataTestId} from '@hconnect/uikit'
import {Card, CardBox} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {SamplingPointsSettingsContent} from '../components/SamplingPointsSettingsContent'
import {getEnrichedLimsSamplingPoints} from '../helpers/getEnrichedLimsSamplingPoints'
import {useAssetOptionsQuery} from '../hooks/queries/useAssetOptionsQuery'
import {useLimsSamplingPointsQuery} from '../hooks/queries/useLimsSamplingPointsQuery'
import {useSamplingPointsQuery} from '../hooks/queries/useSamplingPointsQuery'
import {useSamplingPointTypeOptionsQuery} from '../hooks/queries/useSamplingPointTypeOptionsQuery'
import {useStorageOptionsQuery} from '../hooks/queries/useStorageOptionsQuery'

import {PageLayout, PageSkeleton, SettingsHelmet} from '@settings/modules/common/components'

export const PageSamplingPointsSettings: React.FC = () => {
  const {t} = useTranslation()

  // Fetching LIMS SamplingPoints for the plant (mapped and unmapped)
  const {data: limsSamplingPoints} = useLimsSamplingPointsQuery()

  // Fetching already mapped SamplingPoints
  const {data: samplingPoints} = useSamplingPointsQuery()

  // Fetching SamplingPoint types to populate "type" column dropdown
  const {data: samplingPointTypeOptions} = useSamplingPointTypeOptionsQuery()

  // Fetching asset options to populate "equipment" column dropdown for "Asset" type SamplingPoints
  const {data: assetOptions} = useAssetOptionsQuery()

  // Fetching storages options to populate "equipment" column dropdown for "Storage" type SamplingPoints
  const {data: storageOptions} = useStorageOptionsQuery()

  // Enriching already mapped limsSamplingPoints with their relevant samplingPoint data
  // and providing a ref to every limsSamplingPoint instance
  const enrichedLimsSamplingPoints = useMemo(
    () => getEnrichedLimsSamplingPoints(limsSamplingPoints, samplingPoints),
    [limsSamplingPoints, samplingPoints]
  )

  const isPageLoading =
    limsSamplingPoints === undefined ||
    samplingPoints === undefined ||
    samplingPointTypeOptions === undefined ||
    assetOptions === undefined ||
    storageOptions === undefined

  if (isPageLoading) {
    return <PageSkeleton />
  }

  if (limsSamplingPoints.length === 0) {
    return (
      <CardBox sx={{textAlign: 'center'}}>
        <InfoOutlined
          sx={{
            mr: 1,
            verticalAlign: 'middle'
          }}
        />
        {t('samplingPointsSettings.noSamplingPoints')}
      </CardBox>
    )
  }

  return (
    <PageLayout {...dataTestId('sampling_points_settings_page')}>
      <SettingsHelmet title={t('samplingPointsSettings.pageTitle')} />
      <Card {...dataTestId('sampling_points_card')}>
        <SamplingPointsSettingsContent
          limsSamplingPoints={enrichedLimsSamplingPoints}
          samplingPointTypeOptions={samplingPointTypeOptions}
          assetOptions={assetOptions}
          storageOptions={storageOptions}
        />
      </Card>
    </PageLayout>
  )
}
