import {useState, useCallback} from 'react'

import {LimsSamplingPoint} from '../types'

export const useHandleSamplingPointsMapping = (limsSamplingPoints: LimsSamplingPoint[]) => {
  const [isMappingSamplingPoints, setIsMappingSamplingPoints] = useState(false)

  const handleSamplingPointsMapping = useCallback(() => {
    setIsMappingSamplingPoints(true)
    let processedForms = 0
    limsSamplingPoints.forEach((limsSamplingPoint) => {
      if (limsSamplingPoint.limsSamplingPointRef.current)
        limsSamplingPoint.limsSamplingPointRef.current
          .mapLimsSamplingPoint()
          .then(() => processedForms++)
          .catch(() => processedForms++)
          .finally(() => {
            if (processedForms === limsSamplingPoints.length) {
              setIsMappingSamplingPoints(false)
            }
          })
    })
  }, [limsSamplingPoints])

  return {isMappingSamplingPoints, handleSamplingPointsMapping}
}
