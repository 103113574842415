import {Material} from '@hconnect/common/types'
import {Stack} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import React, {useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'

import {TreeNode} from '../../../common/types'
import {ItemDetailsLayout} from '../../plant-setup-item-layout'
import {
  Config,
  ConfigField,
  configFieldsMap,
  ConfigType,
  EntityConfigDto,
  fields
} from '../../types'

import {ConfigControlledField} from './ConfigControlledField'

interface ConfigFormProps {
  selectedItem: TreeNode
  configNotCreated: boolean
  config: EntityConfigDto
  onSubmit: (data: EntityConfigDto) => Promise<void>
  materials: Material[]
  type: ConfigType
  showAllMaterials?: boolean
}

export const ConfigForm: React.FC<ConfigFormProps> = ({
  selectedItem,
  onSubmit,
  config,
  configNotCreated,
  materials,
  type
}) => {
  const defaultValues = useMemo(() => {
    const defaultConfig = {
      localName: '',
      workCenter: '',
      commissEndDate: '',
      mothballed: 'false',
      commissStartDate: ''
    } as Config

    const shouldReturnDefaultConfig =
      configNotCreated ||
      !config ||
      !config.payload?.productionData ||
      config.payload.productionData.length === 0

    if (shouldReturnDefaultConfig) {
      return defaultConfig
    }

    return config.payload.productionData.reduce(
      (acc, item) => {
        acc[item.name] = item.value
        return acc
      },
      {} as Record<string, string>
    ) as Config
  }, [configNotCreated, config])

  const {
    handleSubmit,
    control,
    reset,
    watch,
    clearErrors,
    formState: {isDirty, isSubmitSuccessful, isSubmitting, isValid}
  } = useForm({
    mode: 'all',
    shouldFocusError: false,
    defaultValues
  })

  const watchStartDate = watch('commissStartDate')
  const watchEndDate = watch('commissEndDate')

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues)
    }
  }, [isSubmitSuccessful, defaultValues, reset])

  const submit = async (data: Config) => {
    if (!isDirty) return
    const newConfig = structuredClone(config)

    newConfig.payload.productionData = Object.keys(data).map((key) => ({
      name: key,
      value: data[key]
    }))

    await onSubmit(newConfig)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ItemDetailsLayout
        saveButton={{
          hide: false,
          onClick: handleSubmit(submit),
          disabled: isSubmitting || !isDirty || !isValid,
          ButtonProps: {
            loadingPosition: 'start',
            loading: isSubmitting
          }
        }}
      >
        <Stack maxWidth="500px" spacing={3}>
          {configFieldsMap[type].map((fieldType: ConfigField) => {
            const field = fields[fieldType]
            return (
              <ConfigControlledField
                upmId={selectedItem.upmId}
                clearErrors={clearErrors}
                control={control}
                field={field}
                materials={materials}
                key={`${field.label}`}
                startDate={watchStartDate}
                endDate={watchEndDate}
              />
            )
          })}
        </Stack>
      </ItemDetailsLayout>
    </LocalizationProvider>
  )
}
