import {Loader} from '@hconnect/uikit/src/lib2'
import {useMemo} from 'react'

import {useSettingsQuery} from '../../../common/providers'
import {useUrlParam} from '../../../routing'
import {useCreateEntityConfig} from '../../hooks/upmEntityConfig/useCreateEntityConfig'
import {useEntityConfig} from '../../hooks/upmEntityConfig/useEntityConfig'
import {useUpdateEntityConfig} from '../../hooks/upmEntityConfig/useUpdateEntityConfig'
import {ItemDetailsLayout} from '../../plant-setup-item-layout'
import {useSelectedItem} from '../../providers'
import {EntityConfigDto} from '../../types'

import {ConfigForm} from './ConfigForm'

import {TreeNode, UpmEntityType} from '@settings/modules/common/types'

const departmentHeaders = {
  '05_5': 'WHR',
  '05_6': 'COPG',
  '06_1': 'FuelMaterialsHandling',
  '06_4': 'CoalMill',
  '10_2': 'Quarry',
  '20_4': 'Crusher',
  '25_1': 'RawMaterialDryer',
  '25_2': 'RawMaterialDeforesting',
  '30_4': 'RawMill',
  '40_4': 'Kiln',
  '41_1': 'ClayCalcination',
  '45_1': 'CementDryer',
  '49_0': 'CarbonCaptureUtilization',
  '50_4': 'CementMill',
  '55_1': 'CementBlending',
  Plant: 'Plant'
}

const CONFIG_TYPE = 'JanusProductionData'

const getInitialConfig = (
  plantUpmId: string,
  upmType: UpmEntityType,
  departmentConfig?: EntityConfigDto,
  upmId?: string
): EntityConfigDto => {
  return {
    metadata: {
      configurationType: CONFIG_TYPE,
      plantId: plantUpmId,
      typeUpmId: upmId ?? '',
      upmType
    },
    payload: {productionData: departmentConfig?.payload?.productionData || []}
  }
}

interface ConfigurationProps {
  selectedItem: TreeNode
}

export const Configuration: React.FC<ConfigurationProps> = ({selectedItem}) => {
  const entityCodesPath = useMemo(() => {
    return selectedItem.entityCodesPath ? selectedItem.entityCodesPath : ''
  }, [selectedItem.entityCodesPath])

  const {upmPlantId} = useSelectedItem()
  const {
    data: entityConfig,
    isLoading,
    isFetching
  } = useEntityConfig(upmPlantId, selectedItem.upmId, selectedItem.itemType)
  const {mutateAsync: createEntityConfig} = useCreateEntityConfig()
  const {mutateAsync: updateEntityConfig} = useUpdateEntityConfig()

  const plantCode = useUrlParam('plantCode')
  const {
    data: materials,
    isLoading: isLoadingMaterials,
    isFetching: isFetchingMaterials
  } = useSettingsQuery('materials', [plantCode])
  const configNotCreated = useMemo(() => !isLoading && !entityConfig, [entityConfig, isLoading])
  const isLoadingOrFetching = isLoading || isFetching || isLoadingMaterials || isFetchingMaterials

  if (isLoadingOrFetching) {
    return (
      <ItemDetailsLayout>
        <Loader />
      </ItemDetailsLayout>
    )
  }

  const initialConfig: EntityConfigDto = getInitialConfig(
    upmPlantId,
    selectedItem.itemType,
    entityConfig,
    selectedItem.upmId
  )

  const config = entityConfig || initialConfig

  const handleCreateConfig = (entityConfig: EntityConfigDto) =>
    createEntityConfig({plantUpmId: upmPlantId, entityConfig, type: selectedItem.itemType})

  const handleUpdateConfig = (entityConfig: EntityConfigDto) =>
    updateEntityConfig({plantUpmId: upmPlantId, entityConfig, type: selectedItem.itemType})

  const onSubmit = configNotCreated ? handleCreateConfig : handleUpdateConfig

  const type = selectedItem.itemType === UpmEntityType.Plant ? 'Plant' : entityCodesPath

  return (
    <ConfigForm
      selectedItem={selectedItem}
      configNotCreated={configNotCreated}
      config={config}
      onSubmit={onSubmit}
      materials={materials || []}
      type={departmentHeaders[type]}
    />
  )
}
