import {dataTestId} from '@hconnect/uikit'
import {Table, TableContainer, SortDirection} from '@mui/material'
import {useState, useMemo} from 'react'

import {
  LimsSamplingPoint,
  AutocompleteOption,
  SamplingPointTypesAutocompleteOption
} from '../../types'

import {SamplingPointsSettingsTableBody} from './SamplingPointsSettingsTableBody'
import {SamplingPointsSettingsTableHeader} from './SamplingPointsSettingsTableHeader'

export interface SamplingPointsSettingsTableProps {
  limsSamplingPoints: LimsSamplingPoint[]
  samplingPointTypeOptions: SamplingPointTypesAutocompleteOption[]
  assetOptions: AutocompleteOption[]
  storageOptions: AutocompleteOption[]
  plantCode: string
}

export const SamplingPointsSettingsTable: React.FC<SamplingPointsSettingsTableProps> = ({
  limsSamplingPoints,
  samplingPointTypeOptions,
  assetOptions,
  storageOptions,
  plantCode
}) => {
  const [orderDirection, setOrderDirection] = useState<SortDirection>('asc')

  const orderedLimsSamplingPoints = useMemo(() => {
    return [...limsSamplingPoints].sort((current, next) => {
      return orderDirection === 'asc'
        ? current.code.localeCompare(next.code)
        : next.code.localeCompare(current.code)
    })
  }, [limsSamplingPoints, orderDirection])

  return (
    <TableContainer {...dataTestId('sampling_points_table')}>
      <Table size="small">
        <SamplingPointsSettingsTableHeader
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
        />
        <SamplingPointsSettingsTableBody
          limsSamplingPoints={orderedLimsSamplingPoints}
          samplingPointTypeOptions={samplingPointTypeOptions}
          assetOptions={assetOptions}
          storageOptions={storageOptions}
          plantCode={plantCode}
        />
      </Table>
    </TableContainer>
  )
}
