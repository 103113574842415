import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {invalidateSettingsQuery} from '../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'

export const useCreateEntityConfig = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.addEntityConfig>[0]
  >(mutations.addEntityConfig, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: () => {
      notify('success', t('success.configCreated'))
    },
    onSettled: (_data, _error, variables) => {
      invalidateSettingsQuery(
        'upmEntityConfig',
        variables.plantUpmId,
        variables.entityConfig.metadata.typeUpmId
      )
    }
  })
}
