import {Material, MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Autocomplete, Box, TextField} from '@mui/material'
import {DatePicker} from '@mui/x-date-pickers'
import moment from 'moment'
import {Control, Controller, FieldValues, Path, UseFormClearErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {UnitType} from '../../../common/enums'
import {getIsValueFieldValid} from '../../../common/helpers/getIsValueFieldValid'
import {Config, FieldType} from '../../types'

import {MaterialSelection} from './MaterialSelection'

import {ControlledCheckbox} from '@settings/modules/common/components'

interface ConfigControlledFieldProps<T extends FieldValues> {
  control: Control<T>
  materials: Material[]
  field: FieldType
  upmId?: string
  translateOptions?: boolean
  endDate?: string | undefined
  startDate?: string | undefined
  clearErrors: UseFormClearErrors<Config>
  labelParams?: {[key: string]: number | string}
  materialType?: MaterialType
}

export const ConfigControlledField = <T extends FieldValues>({
  control,
  materials,
  field,
  upmId,
  startDate,
  endDate,
  clearErrors
}: ConfigControlledFieldProps<T>): JSX.Element => {
  const {t} = useTranslation()
  const {
    label,
    name,
    testId,
    type,
    isNumeric,
    optional,
    options,
    translateOptions,
    labelParams,
    materialType,
    showAllMaterials
  } = field

  const selectOptions = options && options?.length > 0 ? options : materials

  switch (type) {
    case 'Select':
      return (
        <MaterialSelection
          name={name as Path<T>}
          control={control}
          materials={selectOptions as Material[]}
          label={t(`janusConfig.plantSetup.${label}`)}
          testId={`${testId}-${upmId}`}
          key={`${label}-key`}
          materialType={materialType}
          showAllMaterials={showAllMaterials}
        />
      )
    case 'Text':
      return (
        <Controller
          name={name as Path<T>}
          control={control}
          rules={{
            required: !optional && t('error.required'),
            validate: (value) => isNumeric && getIsValueFieldValid(value, UnitType.Numeric, t)
          }}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => {
            const helperText = (error && error.message) || ''

            return (
              <TextField
                {...dataTestId(`${testId}-${upmId}`)}
                fullWidth
                onChange={onChange}
                value={value}
                label={t(`janusConfig.plantSetup.${label}`)}
                inputRef={ref}
                variant="outlined"
                error={!!error}
                helperText={helperText}
              />
            )
          }}
        />
      )
    case 'Autocomplete':
      return (
        <Controller
          name={name as Path<T>}
          control={control}
          rules={{
            required: t('error.required')
          }}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => {
            const helperText = (error && error.message) || ''

            return (
              <Autocomplete
                {...dataTestId(`${field.testId}-${upmId}`)}
                options={(selectOptions || []) as string[]}
                fullWidth
                disableClearable
                getOptionLabel={(option) =>
                  translateOptions ? t(`janusConfig.plantSetup.configOptions.${option}`) : option
                }
                disablePortal
                onChange={(_, selectedOption) => onChange(selectedOption)}
                value={value}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label={t(`janusConfig.plantSetup.${label}`, labelParams ?? {})}
                      variant="outlined"
                      error={!!error}
                      helperText={helperText}
                      inputRef={ref}
                    />
                  )
                }}
              />
            )
          }}
        />
      )
    case 'Date':
      return (
        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <Controller
            name={'commissStartDate' as Path<T>}
            control={control}
            rules={{
              required: t('error.required'),
              validate: (value) =>
                !endDate ||
                (value && moment(value).isSameOrBefore(endDate)) ||
                t('error.startAfterEndDate')
            }}
            render={({field, fieldState}) => (
              <DatePicker
                {...field}
                inputFormat="DD.MM.YYYY"
                label={t('janusConfig.plantSetup.commissStartDate')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...dataTestId(`start-date-${upmId}`)}
                  />
                )}
                onChange={(value) => {
                  field.onChange(value)
                  if (value && endDate && moment(value).isSameOrBefore(endDate)) {
                    clearErrors('commissStartDate')
                    clearErrors('commissEndDate')
                  }
                }}
              />
            )}
          />
          <span>-</span>
          <Controller
            name={'commissEndDate' as Path<T>}
            control={control}
            rules={{
              required: t('error.required'),
              validate: (value) =>
                !startDate ||
                (value && moment(value).isSameOrAfter(startDate)) ||
                t('error.endBeforeStartDate')
            }}
            render={({field, fieldState}) => (
              <DatePicker
                {...field}
                inputFormat="DD.MM.YYYY"
                label={t('janusConfig.plantSetup.commissEndDate')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...dataTestId(`end-date-${upmId}`)}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
                onChange={(value) => {
                  field.onChange(value)
                  if (value && startDate && moment(value).isSameOrAfter(startDate)) {
                    clearErrors('commissStartDate')
                    clearErrors('commissEndDate')
                  }
                }}
              />
            )}
          />
        </Box>
      )

    case 'Checkbox':
      return (
        <ControlledCheckbox
          {...dataTestId(`${name}-${upmId}`)}
          formDataName={name as Path<T>}
          parseValue={(value) => value === 'true'}
          formatValue={(value) => String(value)}
          label={t(`janusConfig.plantSetup.${label}`)}
          control={control}
        />
      )

    default:
      return <div />
  }
}
