export type KilnConfigDto = {
  metadata: {
    configurationType: string
    plantId: string
    upmType: 'Department'
    typeUpmId: string
  }
  payload: {
    layout: object
    configuration: {
      name: string | null
      dimensions: {
        length: number | string | null
        diameter: number | string | null
      }
      isRotationClockwise: boolean
      feedSignals: string[] | null
      speedSignals: string[] | null
    }
    signalMappings: null
  }
}
