import {dataTestId} from '@hconnect/uikit'
import {ArrowDownward, ArrowUpward} from '@mui/icons-material'
import {SortDirection, Stack, TableCell, TableCellProps, useTheme} from '@mui/material'

interface SamplingPointsSettingsTableHeaderCellProps extends TableCellProps {
  orderDirection?: SortDirection
  onClick?: (event: React.MouseEvent<HTMLTableCellElement>) => void
  'data-test-id'?: string
}

export const SamplingPointsSettingsTableHeaderCell: React.FC<
  SamplingPointsSettingsTableHeaderCellProps
> = ({
  children,
  orderDirection,
  onClick,
  'data-test-id': providedDataTestId = 'sampling_point_table_header_cell'
}) => {
  const theme = useTheme()
  return (
    <TableCell
      sx={{
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[800],
        cursor: onClick ? 'pointer' : 'default'
      }}
      onClick={onClick}
      data-test-id={providedDataTestId}
    >
      <Stack direction="row" alignItems="center">
        {children}
        {orderDirection ? (
          orderDirection === 'asc' ? (
            <ArrowUpward
              sx={{fontSize: '0.95rem', ml: 1}}
              {...dataTestId('sampling_point_table_header_cell_order_asc_icon')}
            />
          ) : (
            <ArrowDownward
              sx={{fontSize: '0.95rem', ml: 1}}
              {...dataTestId('sampling_point_table_header_cell_order_desc_icon')}
            />
          )
        ) : null}
      </Stack>
    </TableCell>
  )
}
