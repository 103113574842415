import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {UseQueryResult} from 'react-query'

import {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'
import {SAMPLING_POINT_TYPES_QUERY_KEY} from '../../api/queries/getSamplingPointTypes'
import {getSamplingPointTypeOptions} from '../../helpers'
import {SamplingPointTypesAutocompleteOption} from '../../types'

import {useSettingsQuery} from '@settings/modules/common/providers'

export function useSamplingPointTypeOptionsQuery(): UseQueryResult<
  SamplingPointTypesAutocompleteOption[]
> {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  return useSettingsQuery(SAMPLING_POINT_TYPES_QUERY_KEY, [], {
    select: getSamplingPointTypeOptions,
    onError: (error) => {
      const axiosError = error as AxiosError<ErrorResponse>
      const errorDetails =
        axiosError?.response?.data.detail ?? axiosError.message ?? axiosError.response?.status

      notifyIfErrorMessage(
        t('samplingPointsSettings.queries.fetchSamplingPointTypesError', {
          details: errorDetails
        }),
        raiseError,
        notify
      )
    }
  })
}
