import {dataTestId} from '@hconnect/uikit'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {Autocomplete, TextField, Typography, Stack, Checkbox, Chip} from '@mui/material'
import React, {useMemo} from 'react'

export type AutocompleteOption = {
  value: string
  label: string
}

export interface MultiAutoCompleteProps {
  values?: string[]
  options: AutocompleteOption[]
  error?: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  onChange?: (values: string[]) => void | Promise<void>
  'data-test-id'?: string
}

export function MultiAutoComplete({
  values = [],
  options,
  error,
  label,
  placeholder,
  isDisabled = false,
  onChange,
  'data-test-id': testId = 'multi-auto-complete'
}: MultiAutoCompleteProps): JSX.Element {
  const optionValueToLabelMap = useMemo(
    () => Object.fromEntries(options.map((option) => [option.value, option.label])),
    [options]
  )
  return (
    <Autocomplete
      multiple
      disabled={isDisabled}
      value={values}
      options={options.map(({value}) => value)}
      getOptionLabel={(option) => optionValueToLabelMap[option]}
      onChange={(_, values) => void onChange?.(values)}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            sx={{
              '& .MuiFilledInput-root': {
                backgroundColor: (theme) => theme.palette.background.paper,
                pt: 3
              }
            }}
            variant="filled"
            label={label}
            helperText={error}
            error={!!error}
            placeholder={placeholder}
            {...dataTestId(`${testId}_input`)}
          />
        )
      }}
      renderTags={(values) => {
        return values.map((value: string, index: number) => {
          const chipLabel = (
            <Stack>
              <Typography variant="h6">{optionValueToLabelMap[value]}</Typography>
              <Typography variant="caption">{value}</Typography>
            </Stack>
          )
          return (
            <Chip
              key={index}
              label={chipLabel}
              onDelete={() => {
                values.splice(index, 1)
                void onChange?.(values)
              }}
              sx={{py: 3, m: 0.5}}
            />
          )
        })
      }}
      renderOption={(props, optionValue, {selected}) => {
        return (
          <li key={optionValue} {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{marginRight: 8}}
              checked={selected}
            />
            <Stack spacing={0.5}>
              <Typography variant="subtitle1">{optionValueToLabelMap[optionValue]}</Typography>
              <Typography variant="caption">{optionValue}</Typography>
            </Stack>
          </li>
        )
      }}
      sx={{width: (theme) => theme.spacing(50)}}
    />
  )
}
