import React, {useCallback, useContext, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermissionsQuery} from '../../permissions'
import {useUrlParam} from '../../routing'
import {
  tabToCustomFilterMap,
  tabToPermissionsMap,
  upmNodeToTabsMap
} from '../plant-setup-tabs/tabs.config'

import {useSelectedItem} from './SelectedItemProvider'

import {HeaderTab} from '@settings/modules/common/components/HeaderTabs'
import {useLatestQuery} from '@settings/modules/common/hooks'
import {checkMultiplePermissions} from '@settings/modules/permissions/helpers/checkMultiplePermissions'
import {checkPermissionAccessByDataScope} from '@settings/modules/permissions/helpers/checkPermissionAccessByDataScope'

interface ItemDetailsState {
  availableTabs: HeaderTab[]
  selectedTab: HeaderTab | undefined
  handleTabChange: (tab: HeaderTab) => void
}

const ItemDetailsContext = React.createContext<ItemDetailsState | undefined>(undefined)

export const useItemDetails = () => {
  const context = useContext(ItemDetailsContext)
  if (!context) throw new Error('Cannot use selected item context outside of a context provider')
  return context
}

export const ItemDetailsProvider = ({children}: {children: React.ReactNode}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')

  const {data: userPermissions} = usePermissionsQuery()
  const {data: latestData} = useLatestQuery()

  const {selectedItem} = useSelectedItem()

  const availableTabs: HeaderTab[] = useMemo(() => {
    if (!selectedItem || !userPermissions || !latestData) return []

    const tabsForSelectedNodeType = upmNodeToTabsMap[selectedItem.itemType]

    const tabsFilteredByPermission = tabsForSelectedNodeType.filter((tab) => {
      const requiredPermissions = tabToPermissionsMap[tab]
      if (requiredPermissions.length)
        checkMultiplePermissions({
          permissions: userPermissions,
          requiredPermissionTypes: requiredPermissions,
          testFn: (permission) =>
            checkPermissionAccessByDataScope(
              latestData.country,
              plantCode,
              permission.parsedDataScope
            )
        })

      return true
    })

    const tabsFilteredByCustomFilter = tabsFilteredByPermission.filter((tab) =>
      tabToCustomFilterMap[tab](selectedItem)
    )

    return tabsFilteredByCustomFilter.map((tab) => {
      return {
        value: tab,
        label: t(`janusConfig.${tab}`)
      }
    })
  }, [selectedItem, userPermissions, latestData, plantCode, t])

  const [selectedTab, setSelectedTab] = useState<HeaderTab | undefined>(availableTabs[0])

  const memoizedSelectedTab = useMemo(() => {
    if (!selectedTab || !availableTabs.find(({value}) => value === selectedTab.value)) {
      return availableTabs[0]
    }
    return selectedTab
  }, [selectedTab, availableTabs])

  const handleTabChange = useCallback((tab: HeaderTab) => {
    setSelectedTab(tab)
  }, [])

  return (
    <ItemDetailsContext.Provider
      value={{
        availableTabs,
        selectedTab: memoizedSelectedTab,
        handleTabChange
      }}
    >
      {children}
    </ItemDetailsContext.Provider>
  )
}
